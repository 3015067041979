import mitsubishiAfter from "../assets/home/mitsubishiAfter.jpg";
import volkswagenAfter from "../assets/home/volkswagenAfter.jpg";
import teslaAfter from "../assets/home/teslaAfter.jpg";
import mitsubishiBefore from "../assets/home/mitsubishiBefore.jpg";
import volkswagenBefore from "../assets/home/volkswagenBefore.jpg";
import teslaBefore from "../assets/home/teslaBefore.jpg";

export default function MitaMobileComponent() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: "50px",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", padding: "20px" }}>
          <h1
            style={{
              fontSize: "clamp(50px, 6vw, 80px)",
              color: "#545454",
              textAlign: "center",
              marginBottom: "30px",
            }}
            className="barlow-condensed-bold"
          >
            MITSUBISHI <br /> OUTLANDER
          </h1>
          <p style={{
                    fontSize: "25px",
                    lineHeight:"40px",
                    color: "#262626",
                    textAlign: "left",
                    width:"90%",
                    margin:"auto",
                    marginTop:"50px",
                    marginBottom:"50px"
                    }}
                    className="open-sans">
                Den høyre fremre skjermen ble reparert uten behov for delutskifting eller lakkering. Reparasjonen ble fullført på tre timer og kostet bare en fjerdedel av prisen for en tradisjonell reparasjon. Reparasjonen ble gjennomført med spesialverktøy og teknikerens dyktighet, helt uten bruk av skadelige kjemikalier for miljøet.
            </p>
          <div
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={mitsubishiBefore}
              alt="Mitsubishi"
            />
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={mitsubishiAfter}
              alt="Mitsubishi"
            />
          </div>
          <h1
            style={{
                fontSize: "clamp(50px, 6vw, 80px)",
              color: "#FF4C4C",
              textAlign: "center",
              margin: "40px 0",
              marginTop:"70px"
            }}
            className="barlow-condensed-bold"
          >
            VOLKSWAGEN <br /> ID4 GTX
          </h1>
          <p style={{
                    fontSize: "25px",
                    lineHeight:"40px",
                    color: "#262626",
                    textAlign: "left",
                    width:"90%",
                    margin:"auto",
                    marginTop:"50px",
                    marginBottom:"50px"
                    }}
                    className="open-sans">
                        Bakluken med bulker ble utbedret ved hjelp av PDR-metoden, noe som sparte både tid og behovet for å bytte ut deler. Etter bare to timer var bilen ferdig og forlot verkstedet vårt i perfekt stand, som om skaden aldri hadde skjedd.   
            </p>
          <div
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={volkswagenBefore}
              alt="Volkswagen"
            />
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={volkswagenAfter}
              alt="Volkswagen"
            />
          </div>
        </div>
  
        <div style={{ width: "100%", padding: "20px" }}>
        <h1
            style={{
                fontSize: "clamp(50px, 6vw, 80px)",
              color: "#545454",
              textAlign: "center",
              marginBottom: "30px",
              marginTop:"70px"
            }}
            className="barlow-condensed-bold"
          >
            TESLA <br /> MODEL 3
          </h1>
          <p
            style={{
                fontSize: "25px",
                lineHeight:"40px",
                color: "#262626",
                textAlign: "left",
                width:"90%",
                margin:"auto",
                marginTop:"50px",
                marginBottom:"50px"
            }}
            className="open-sans"
          >
                        Den bakre høyre døren på bilen, som har fargen Pearl White Multi-Coat, har en nyanse som er svært utfordrende å gjenskape helt nøyaktig. Ved å reparere uten lakkering ble den originale lakken bevart, noe som sikret at det ikke oppstod synlige fargeforskjeller mot de omkringliggende delene.
          </p>
          <div
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={teslaBefore}
              alt="Mitsubishi Before"
            />
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={teslaAfter}
              alt="Volkswagen Before"
            />
          </div>
        </div>
      </div>
    );
  }
  