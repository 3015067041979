import mitsubishiAfter from "../assets/home/mitsubishiAfter.jpg";
import volkswagenAfter from "../assets/home/volkswagenAfter.jpg";
import teslaAfter from "../assets/home/teslaAfter.jpg";
import mitsubishiBefore from "../assets/home/mitsubishiBefore.jpg";
import volkswagenBefore from "../assets/home/volkswagenBefore.jpg";
import teslaBefore from "../assets/home/teslaBefore.jpg";


export default function MitaComponent () {

    return(
        <div style={{display:"flex", flexDirection:"row", marginTop:"50px", width:"100%"}}>
            <div style={{width:"50%"}}>
            <h1 style={{
                fontSize: "80px",
                color: "#545454",
                textAlign: "left",
                width:"fit-content",
                margin:"auto",
                marginTop:"50px",
                marginBottom:"130px",
                }}
                className="barlow-condensed-bold">
                MITSUBISHI <br /> OUTLANDER
            </h1>
                <div style={{
                    width: "90%", 
                    height: "auto",
                    overflow: "hidden",
                    margin:"auto"
                }}>
                    <img
                        style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        objectPosition: "center",
                        }}
                        src={mitsubishiAfter}
                        alt="Mitsubishi"
                    />
                </div>
                <h1 style={{
                fontSize: "80px",
                color: "#FF4C4C",
                textAlign: "left",
                width:"fit-content",
                margin:"auto",
                marginTop:"80px",
                marginBottom:"80px"
                }}
                className="barlow-condensed-bold">
                VOLKSWAGEN <br /> ID4 GTX
                </h1>
                <div style={{
                    width: "80%", 
                    height: "auto",
                    overflow: "hidden",
                    margin:"auto"
                    }}>
                    <img
                        style={{
                        width: "120%",
                        position:"relative",
                        right:"10%",
                        height: "auto",
                        objectFit: "cover",
                        objectPosition: "center",
                        }}
                        src={volkswagenAfter}
                        alt="Volkswagen"
                    />
                </div>
                <h1 style={{
                fontSize: "80px",
                color: "#545454",
                textAlign: "left",
                width:"fit-content",
                margin:"auto",
                marginTop:"80px",
                marginBottom:"50px"
                }}
                className="barlow-condensed-bold">
                TESLA <br /> MODEL 3
                </h1>
                <div style={{
                    width: "90%", 
                    height: "auto",
                    overflow: "hidden",
                    margin:"auto"
                    }}>
                    <img
                        style={{
                        width: "110%",
                        height: "auto",
                        objectFit: "cover",
                        objectPosition: "center",
                        }}
                        src={teslaAfter}
                        alt="Tesla"
                    />
                </div>
            </div>
            <div style={{ width:"50%" }}>
                

                <div style={{
                        marginTop:"50px",
                        marginBottom:"80px",
                        width: "90%", 
                        height: "auto",
                        overflow: "hidden",
                        margin:"auto",
                        marginTop:"80px"
                        }}>
                        <img
                            style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            objectPosition: "center",
                            }}
                            src={mitsubishiBefore}
                            alt="Mitsubishi"
                        />
                </div>
                <p style={{
                    fontSize: "25px",
                    lineHeight:"40px",
                    color: "#262626",
                    textAlign: "left",
                    width:"90%",
                    margin:"auto",
                    marginTop:"50px",
                    marginBottom:"50px"
                    }}
                    className="open-sans">
                Den høyre fremre skjermen ble reparert uten behov for delutskifting eller lakkering. Reparasjonen ble fullført på tre timer og kostet bare en fjerdedel av prisen for en tradisjonell reparasjon. Reparasjonen ble gjennomført med spesialverktøy og teknikerens dyktighet, helt uten bruk av skadelige kjemikalier for miljøet.
                </p>
                <div style={{
                        marginTop:"100px",
                        marginBottom:"80px",
                        width: "90%", 
                        height: "auto",
                        overflow: "hidden",
                        margin:"auto"
                        }}>
                        <img
                            style={{
                            width: "100%",
                            height: "490px",
                            objectFit: "cover",
                            objectPosition: "center",
                            }}
                            src={volkswagenBefore}
                            alt="Volkswagen"
                        />
                </div>
                <p style={{
                    fontSize: "25px",
                    lineHeight:"40px",
                    color: "#262626",
                    textAlign: "left",
                    width:"90%",
                    margin:"auto",
                    marginTop:"110px",
                    marginBottom:"170px"
                    }}
                    className="open-sans">
                        Bakluken med bulker ble utbedret ved hjelp av PDR-metoden, noe som sparte både tid og behovet for å bytte ut deler. Etter bare to timer var bilen ferdig og forlot verkstedet vårt i perfekt stand, som om skaden aldri hadde skjedd.                
                </p>
                <div style={{
                        marginTop:"50px",
                        marginBottom:"80px",
                        width: "90%", 
                        height: "400px",
                        overflow: "hidden",
                        margin:"auto"
                        }}>
                        <img
                            style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            objectPosition: "center",
                            }}
                            src={teslaBefore}
                            alt="Tesla"
                        />
                </div>
                <p style={{
                    fontSize: "25px",
                    lineHeight:"40px",
                    color: "#262626",
                    textAlign: "left",
                    width:"90%",
                    margin:"auto",
                    marginTop:"80px",
                    marginBottom:"50px"
                    }}
                    className="open-sans">
                        Den bakre høyre døren på bilen, som har fargen Pearl White Multi-Coat, har en nyanse som er svært utfordrende å gjenskape helt nøyaktig. Ved å reparere uten lakkering ble den originale lakken bevart, noe som sikret at det ikke oppstod synlige fargeforskjeller mot de omkringliggende delene.
                </p>
            </div>
        </div>
    );
}