import {Link } from "react-router-dom";
import picture from "../assets/desktop/background-pdr-article.png"
import video from "../assets/desktop/mainVideo.mp4"

export default function WelcomeTop () {

    return (
        <div className="welcome-container">
            <section className="welcome-container-inner">
            <div className="welcome-inner">
                <h2 className="welcome-text" style={{fontSize: "2rem"}}>Skade på bilen?</h2>
                <h1 className="welcome-text" id="font68">Vi tar vare på bilen din</h1>
                <Link to={"https://pdrpartner.no/pristilbud"}><button className="top-button">Få et pristilbud</button></Link>
            </div>
            <div className="welcome-image">
            <video
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                style={{
                    pointerEvents: "none",
                    display: "block",
                }}
            >
                <source src={video} type="video/mp4" />
            </video>
            </div>
            </section>         
           
        </div>
    );
}